import { Checkbox2 } from "../../../../components/Form/Checkbox/Checkbox";

export const RoleItem = ({
  permissions,
  updatePermissions,
  currentPermissions,
}) => {
  return (
    <div>
      <div className="grid grid-cols-12 gap-6 mt-12">
        {Object.keys(permissions ?? {})?.map((key) => (
          <ul
            key={key}
            className="border col-span-4 divide-y divide-gray-200 rounded-sm"
          >
            <li className="bg-gray-50 p-2">
              <Checkbox2
                title={
                  <span className="font-bold text-gray-800 capitalize">
                    {key?.replaceAll("_", " ")}
                  </span>
                }
                onChange={(checked) => {
                  const p = (permissions[key] ?? []).map((p) => p._id);
                  updatePermissions(p, checked ? "add" : "remove");
                }}
                checked={(permissions[key] ?? []).every((p) =>
                  currentPermissions.includes(p._id)
                )}
              />
            </li>
            {permissions[key]?.map((permission) => (
              <li key={permission?.name} className="p-2">
                <Checkbox2
                  title={
                    <span className=" text-gray-800">{permission?.name}</span>
                  }
                  onChange={(checked) => {
                    updatePermissions(
                      [permission._id],
                      checked ? "add" : "remove"
                    );
                  }}
                  checked={currentPermissions?.includes(permission._id)}
                />
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};
