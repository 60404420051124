import React from "react";
import { NavItem } from "../Navbar/NavItem";
import Logo from "../Logo/Logo";
import { useModal } from "../../hooks";
import { LogoutPrompt } from "../LogoutPrompt/LogoutPrompt";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { RectangleStackIcon } from "@heroicons/react/20/solid";
import { useLogout } from "../../hooks/useLogout";
import { useProfile } from "../../pages/Account/hooks/useProfile";
import { useRep } from "../../pages/Account/hooks/useRep";
const Sidebar = () => {
  const { Modal, showModal } = useModal();
  const {rep}=useRep()
  const [showSidebar, setShowSidebar] = useState(false);
  const { handleLogout } = useLogout();
  return (
    <div>
      <div
        onClick={() => {
          setShowSidebar(!showSidebar);
        }}
        className="absolute top-0 left-0 z-[1000] bg-[#fef9f4] w-[60px] h-[60px] border rounded-sm border-primary lg:hidden flex items-center justify-center cursor-pointer"
      >
        <Bars3BottomLeftIcon className="w-7 h-7 text-primary" />
      </div>
      <span className="sr-only">fixed-bg</span>
      <div
        onClick={() => setShowSidebar(false)}
        className={`fixed top-0 left-0 h-[100%] w-[100%] bg-[#00000056] ${
          showSidebar ? "z-[1000] opacity-1" : "-z-[1000] opacity-0"
        } lg:hidden duration-[0.5s]`}
      />
      <div
        className={`lg:flex justify-between fixed left-0  z-[1000] bg-[#fff] border ${
          showSidebar ? "translate-x-[0]" : "-translate-x-[100%]"
        } duration-[0.5s] lg:duration-0 lg:translate-x-0`}
      >
        <span className="absolute top-[120px]  left-0 w-[5px] h-12 bg-primary block rounded-ee-[20px] rounded-se-[20px]"></span>
        <div
          onClick={() => {
            setShowSidebar(!showSidebar);
          }}
          className="absolute top-0 right-0 z-[1000] w-[40px] h-[40px] lg:hidden flex items-center justify-center cursor-pointer"
        >
          <XMarkIcon className="w-7 h-7 text-primary" />
        </div>
        <div className="shadow-lg  w-[250px] h-[100vh] ">
          <div className="pt-[30px] pl-10 flex items-center gap-5">
            <Logo>{rep?.organization?.organization_name}</Logo>
          </div>
          <div className=" px-6  mt-[50px]">
            <NavItem
              onClick={() => {
                setShowSidebar(!showSidebar);
              }}
            />
            <div
              onClick={() => {
                showModal();
              }}
              to="/"
              className="text-primary  cursor-pointer  flex items-center gap-4   px-2 py-3  w-[100%]"
            >
              <RectangleStackIcon className="h-5 w-5" />
              <span className="block">Logout</span>
            </div>
          </div>
        </div>
      </div>
      {Modal({
        children: (
          <div>
            <LogoutPrompt
              cancel={showModal}
              logout={() => {
                handleLogout();
              }}
            />
          </div>
        ),
        showCloseIcon: true,
        size: "sm",
        dismissOnclickOutside: true,
      })}
    </div>
  );
};
export default Sidebar;
