import React from "react";
import { Badge, Container, Header, Heading } from "../../components";
import avatar from "../../assets/img/avatar2.png";
import { useProfile } from "./hooks/useProfile";
import { formatDate } from "../../utils";
import { useRoles } from "../Settings/Roles/hook/useRoles";
import { useRep } from "./hooks/useRep";
export const AccountInfo = () => {
  const { data } = useProfile();
  const { rep } = useRep();
  const { role } = useRoles(data?.role_id);
  return (
    <div>
      <Header>
        <Heading>Account Information</Heading>
      </Header>
      <div className="lg:w-[600px] md:w-[500px] w-[350px] mt-5 lg:mt-0 md:ml-0 lg:ml-0">
        <Container>
          <section className="p-3 text-[14px] break-words">
            <div className="flex justify-between items-center">
              <Heading>My Profile</Heading>
              {/* <div className="text-sm flex items-center gap-2">
                <Link to="/settings/profile" className=" text-primary">
                  <Edit className="w-6 h-6 text-primary" />
                </Link>
              </div> */}
            </div>
            <dt className=" mt-3 flex  gap-2">
              <img
                src={avatar}
                alt="avatar"
                className="w-[100px] h-[100px] rounded-full object-cover"
              />
            </dt>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Account Name:</dt>
              <dt className="col-span-4 capitalize">{`${
                data?.first_name ?? "..."
              } ${data?.last_name ?? "..."}`}</dt>
            </dl>

            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500 ">Email Address:</dt>
              <dt className="col-span-4 text-[14px]">{data?.email ?? "..."}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Login ID:</dt>
              <dt className="col-span-4">{data?.login_id ?? "..."}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Phone Number:</dt>
              <dt className="col-span-4">{data?.phone_number ?? "..."}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Gender:</dt>
              <dt className="col-span-4 capitalize">{data?.gender ?? "..."}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Time Created:</dt>
              <dt className="col-span-4">{formatDate(data?.createdAt)}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Role:</dt>
              <dt className="col-span-4 flex flex-wrap gap-2">
                <Badge status="active">
                  {role?.name.replace("_", " ") ?? "..."}
                </Badge>
              </dt>
            </dl>

            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Organization Name:</dt>
              <dt className="col-span-4 flex flex-wrap gap-2">
                {rep?.organization?.organization_name ?? "..."}
              </dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Organization Email:</dt>
              <dt className="col-span-4 flex flex-wrap gap-2">
                {rep?.organization?.email ?? "..."}
              </dt>
            </dl>
          </section>
        </Container>
      </div>
    </div>
  );
};
