import React from "react";
import {
  Container,
  Header,
  Heading,
  Pagination,
  SubHeading,
} from "../../components";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import { AuditTable } from "./component/AuditTable";
import { AuditData, columns } from "./component/MockData";
import { useState } from "react";
import Entries from "../../components/Filter/Entries";
import { usePagination, useTableSerialNumber } from "../../hooks";
import { TabFilter } from "../Dashboard/component/TabFilter";

export const Audit = () => {
  const { paginatedData, page, setPage } = usePagination(AuditData);
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div>
      <Header>
        <div className="flex  flex-col lg:flex-row lg:items-center items-start ml-3 lg:ml-0 gap-3">
          <Heading>Audit Logs</Heading>
          <SearchFilter placeholder={"Search by event, time or user....."} />
        </div>
      </Header>
      <div className="lg:my-5 mt-20 lg:mt-0 mb-5" >
        <SubHeading>All Audit Logs</SubHeading>
      </div>
      <div className="my-5">
        <TabFilter />
      </div>
      <div>
        <div>
          <AuditTable
            data={paginatedData}
            columns={columns}
            initialSerialNumber={initialSerialNumber}
          />
        </div>
        {AuditData.length !== 0 && (
          <div className="flex flex-col lg:flex-row items-center  justify-center mb-3 gap-y-6">
            <Pagination
              totalItems={AuditData.length}
              currentPage={page}
              handlePageClick={setPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
