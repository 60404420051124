import classNames from "classnames";

export const Badge = ({ children, status = "approved" }) => {
  return (
    <span
      className={classNames(
        "inline-flex items-center  text-sm px-2 py-0.5  font-[500] rounded-md",
        {
          "bg-red-100 text-red-500 border-red-400": [
            "declined",
            "failed",
            "disabled",
            "overdue",
            "Debit",
            "reject",
            "rejected",
          ].includes(status),
          " text-green-600  bg-[#00800013]": [
            "approved",
            "initiation",
            "authorisation",
            "authentication",
            "successful",
            "settled",
            "completed",
            "paid",
            "active",
            "Credit",
          ].includes(status),
          "bg-blue-100 text-blue-500 border-blue-400": status === "in progress",
          " text-yellow-500 bg-yellow-100 ": [
            "awaiting authorization",
            "pending",
            "queued",
            "awaiting approval",
          ].includes(status),
          "bg-gray-100 text-gray-500 border-gray-400 ": status === "pending",
        }
      )}
    >
      {children}
    </span>
  );
};
