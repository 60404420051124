import { PlusIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ExportButton,
  Header,
  Heading,
  Pagination,
  SubHeading,
} from "../../components";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import {
  useConvertFileToJson,
  useModal,
  useTableSerialNumber,
} from "../../hooks";
import { useMutation, useQuery } from "@tanstack/react-query";
import { userService } from "../../services/user.service";
import { RenderData } from "./components/RenderData";
import ContentLoader from "react-content-loader";
import { limit } from "../../constants/limit";
import { useState } from "react";
import { AddUser } from "./components/AddUser";

export const Users = () => {
  const [value, setValue] = useState(undefined);
  const { Modal, showModal } = useModal();
  const [page, setPage] = useState(1);

  const params = {
    limit: limit,
    page: page,
    search: value,
  };
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["users", params],
    queryFn: () => userService.getUsers(params),
  });
  const { mutate } = useMutation({
    mutationKey: ["resend-email"],
    mutationFn: (data) => userService.resendEmailVerification(data),
    onSuccess: () => {
      refetch();
    },
  });

  const initialSerialNumber = useTableSerialNumber(page);
  const { convertJsonToExcel } = useConvertFileToJson("users");
  const { mutate: downloadUsers, isPending } = useMutation({
    mutationKey: ["download-users"],
    mutationFn: (data) => userService.getUsers(data),
    onSuccess: (data) => {
      const users = data?.items?.map((dat) => {
        return {
          FirstName: dat?.first_name,
          LastName: dat?.last_name,
          Email: dat?.email,
          PhoneNumber: dat?.phone_number,
          Gender: dat?.gender,
          ID: dat?._id,
        };
      });
      convertJsonToExcel(users);
    },
  });
  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start gap-3 ml-2 lg:ml-0">
          <Heading>User Management</Heading>
          <SearchFilter
            placeholder="Search by email, first name, last name or phone number"
            value={value}
            setValue={setValue}
            onSearch={() => {
              refetch();
            }}
          />
        </div>
      </Header>
      <div className=" lg:mb-3 mb-7 mt-20 lg:mt-0 flex items-center justify-between">
        <SubHeading>All Users</SubHeading>
        <div className="flex items-center gap-6">
          {data && (
            <ExportButton
              onClick={downloadUsers}
              disabled={isPending}
              name="Users"
            >
              export users
            </ExportButton>
          )}
          <Button variant="outline" onClick={showModal}>
            <PlusIcon className="w-5 h-5 text-primary" />
            <span className="text-primary font-semibold ml-1">
              Create Users
            </span>
          </Button>
        </div>
      </div>
      {isLoading || isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            mutate={mutate}
            initialSerialNumber={initialSerialNumber}
            data={data?.items}
          />
          <div className="flex items-center justify-center mt-2">
            <Pagination
              itemsPerPage={limit}
              totalItems={data?.meta?.total}
              currentPage={page}
              handlePageClick={setPage}
            />
          </div>
        </div>
      )}
      {Modal({
        children: (
          <div>
            <p className="font-semibold text-[16px] mb-4">Create User</p>
            <AddUser showModal={showModal} refetch={refetch} />
          </div>
        ),
      })}
    </div>
  );
};
