import http from "../plugins/http";

class Providers {
  async getProviders(params) {
    try {
      const response = await http.get(`/meta-data/providers`, {
        params: { ...params, status: "active" },
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const providerService = new Providers();
