import React from "react";
import {
  Button,
  Header,
  Heading,
  Input,
  Pagination,
  SubHeading,
} from "../../components";
import ContentLoader from "react-content-loader";
import { RenderData } from "./components/RenderData";
import { useTransactions } from "./hooks/useTransacions";
import { useModal } from "../../hooks";

import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
const TransactionHistory = () => {
  const { Modal, showModal } = useModal();
  const {
    data,
    isLoading,
    setPage,
    initialSerialNumber,
    params,
    page,
    accountStatement,
  } = useTransactions();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const startDate = watch("fromDate");
  const onSubmit = (data) => {
    accountStatement.downloadAccountStatement({
      fromDate: data?.fromDate,
      toDate: data?.toDate,
      showModal,
    });
  };

  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center gap-3">
          <Heading>Transaction History</Heading>
        </div>
      </Header>
      <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between lg:mb-3 mb-3 mt-20 lg:mt-0">
        <SubHeading>All Transactions.</SubHeading>
        <Button onClick={showModal} variant="outline">
          Generate account statement
        </Button> 
      </div>

      {isLoading ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            data={data?.items}
            initialSerialNumber={initialSerialNumber}
          />
          {
            <div className="flex flex-col lg:flex-row items-center  justify-center mb-3 gap-y-6">
              <Pagination
                itemsPerPage={params?.limit}
                totalItems={data?.meta?.total}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          }
        </div>
      )}

      {Modal({
        children: (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <p className="font-medium">Generate Account Statement</p>
            <Input
              type="date"
              label="Start Date"
              id="fromDate"
              {...register("fromDate", {
                required: "Start Date is required",
              })}
              error={errors.fromDate && errors.fromDate.message}
            />

            <Input
              type="date"
              label="End Date"
              id="toDate"
              {...register("toDate", {
                required: "End Date is required",
                validate: (value) => {
                  // Validate end date is after start date
                  return (
                    (value && new Date(value) >= new Date(startDate)) ||
                    "End Date must be after Start Date"
                  );
                },
              })}
              error={errors.toDate && errors.toDate.message}
            />

            <Button
              type="submit"
              isFullWidth
              disabled={accountStatement.downloading}
            >
              <div className="flex items-center gap-2">
                Download Account Statement
                <ArrowDownIcon className="w-5 h-5" />
              </div>
            </Button>
          </form>
        ),
        showCloseIcon: false,
      })}
    </div>
  );
};

export default TransactionHistory;
