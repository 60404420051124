import React, { useMemo, useState } from "react";
import {
  Button,
  Container,
  Header,
  Heading,
  SubHeading,
} from "../../../components";
import { DocumentDownload, Import } from "iconsax-react";
import testCsv from "../../../assets/documents/test.csv";
import { BatchUpload } from "./BatchUpload/BatchUpload";
import { useConvertFileToJson } from "../../../hooks";
export const BatchTransfer = () => {
  const { convertFile, file, clearFile, jsonArray } = useConvertFileToJson();
  const [stages, setStages] = useState("step 1/3-upload file");

  return (
    <div>
      <Header>
        <Heading>Batch Transfers</Heading>
        <p className="mt-1">{stages}</p>
      </Header>

      <div className="flex justify-between items-center mt-12 lg:mt-0">
        <SubHeading>Make a batch transfer</SubHeading>
        <Button variant="outline">
          <a href={testCsv} className="flex items-center gap-2">
            Template
            <DocumentDownload className="h-5 w-5 text-primary" />
          </a>
        </Button>
      </div>
      <div className=" ml-[-20px] md:ml-0 lg:ml-0 ">
        <div>
          <BatchUpload
            convertFile={convertFile}
            file={file}
            clearFile={clearFile}
            jsonArray={jsonArray}
            stages={stages}
            setStages={setStages}
          />
        </div>
      </div>
    </div>
  );
};
