import React from "react";
import { SingleTransferForm } from "./SingleTransferForm";
import {
  Card,
  Container,
  Header,
  Heading,
  SubHeading,
} from "../../../components";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";

export const SingleTransfer = () => {
  return (
    <div>
      <Header>
        <div>
          <Heading>Single Transfer</Heading>
          <p className="mt-1">Make a quick transfer with so much ease</p>
        </div>
      </Header>
      <div className="mb-4 flex  justify-between mt-12 lg:mt-0">
        <div className="w-[550px]">
          <Container>
            <SingleTransferForm />
          </Container>
        </div>
        <HandleGoBack />
      </div>
    </div>
  );
};
