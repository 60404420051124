import { useQuery } from "@tanstack/react-query";
import { userService } from "../../../services/user.service";
import { useEffect } from "react";
import { useStore } from "../../../hooks/useStore";

export const useProfile = () => {
 const token= localStorage.getItem("token")
 const {user:data}=useStore()
  const { data:profile, isLoading, isFetching,isSuccess } = useQuery({
    queryKey: ["get-user"],
    queryFn: () => userService.getUserProfile(),
    enabled:!!token
  });
  useEffect(()=>{
    if(isSuccess){
     useStore.setState({ user:profile }); 
    }
  },[profile,isSuccess])
  return { data, isLoading, isFetching};
};
