import { EmptyState } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { RoleItem } from "./RoleItem";
export const RenderRole = ({
  permissions,
  updatePermissions,
  currentPermissions,
  data,
}) => {
  const navigate = useNavigate();
  if (!data) {
    return (
      <EmptyState
        title="No Role created"
        description="You have not created any role yet."
        showAction
        action={{
          label: "Create a new mandate",
          onClick: () => navigate("/settings/roles"),
        }}
      />
    );
  } else {
    return (
      <RoleItem
        data={data}
        permissions={permissions}
        updatePermissions={updatePermissions}
        currentPermissions={currentPermissions}
      />
    );
  }
};
