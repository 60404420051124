import React from "react";
import { Button, Input } from "../../../components";
import { Link } from "react-router-dom";

export const LoginForm = ({
  register,
  handleSubmit,
  errors,
  isPending,
  mutate,
}) => {
  const onSubmit = (data) => {
    const payload = {
      loginId: data?.loginId,
      password: data?.password,
    };
    mutate(payload);
  };
  return (
    <div className="pt-[40px]">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-12 ">
        <p className=" text-sm">Hello, welcome back!</p>
        <Input
          label="Login ID"
          id="loginId"
          placeholder="enter login Id..."
          {...register("loginId", { required: true })}
          error={errors.loginId && "Login ID is required"}
        />
        <div>
          <Input
            label="Password"
            type="password"
            placeholder="xxxxxx"
            id="password"
            {...register("password", { required: true })}
            error={errors.password && "Password is required"}
          />
          <div className="text-sm flex justify-end font-bold mt-2">
            <Link to={"/forgot-password"} className=" text-primary">
              Forgot your password?
            </Link>
          </div>
        </div>
        <div className=" w-[80px] font-semibold">
          <Button type="submit" disabled={isPending} isFullWidth>
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};
