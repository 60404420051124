import React from "react";
import { firestore } from "../../../firebase";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const useAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const collectionRef = firestore.collection("admin");
    const snapshot = await collectionRef.get();

    const dataObject = {};
    snapshot.docs.forEach((doc) => {
      dataObject[doc.id] = doc.data();
    });

    setIsLoading(false);
    setData(dataObject.KLfc6xrLzdDCRzFirKjS);
  };

  useEffect(() => {
    fetchData();
  }, [location]);
  return {
    data,
    isLoading,
  };
};
