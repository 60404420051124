import { Calendar1, Calendar2, Check } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils";
import { CheckIcon } from "@heroicons/react/24/outline";
export const TicketTable = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Event
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Priority
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              User
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Status
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Time
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber + i}.
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.topic}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                p1
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item.user}
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-2">
                  <p className="bg-green-600 w-[22px] h-[22px] rounded-full flex items-center justify-center">
                    <CheckIcon className="w-4 h-4 text-white " />
                  </p>
                  <p>Resolved</p>
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-2">
                  <Calendar2 variant="Bold" className="w-5 h-5 text-primary" />
                  {formatDate(item?.createdAt.seconds * 1000)}
                </div>
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                <div
                  onClick={() => {
                    navigate(`/ticket/details/${item?.id}`);
                  }}
                  className="flex items-center gap-3 font-semibold cursor-pointer"
                >
                  <span className="px-3 py-1 border  border-light rounded-md">
                    view details
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
