import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
export const Wrapper = ({ children }) => {
  const location = useLocation();
  return (
    <div>
      <Sidebar />
      <div
        className={`lg:pl-[280px] ${
          location.pathname === "/dashboard" ||
          location.pathname.includes("settings")
            ? "pt-[260px] lg:pt-[180px] "
            : "pt-[150px] lg:pt-[150px] "
        } pb-[10px] lg:pr-[30px] px-6 h-[100vh] overflow-hidden overflow-y-auto wrapper z-[10000]`}
      >
        {children}
      </div>
    </div>
  );
};
