import http from "../plugins/http";

class AccountService {
  async getAccountBalance() {
    try {
      const response = await http.get(`/accounts/balance`);

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountDetails() {
    try {
      const response = await http.get(`/accounts/details`);

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}








export const accountService = new AccountService();
