import React from "react";
import { Button, Input, Select, SubHeading } from "../../../../components";
import { useForm } from "react-hook-form";
import { useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { authService } from "../../../../services/auth.service";
import { useLogout } from "../../../../hooks/useLogout";
import { securityService } from "../../../../services/security.service";

export const SecurityQuestion = ({ is_2FA_enabled }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { handleLogout } = useLogout();
  const { data } = useQuery({
    queryKey: ["security-questions"],
    queryFn: () => securityService.getSecurityQuestions(),
  });

  const selectedQuestionOne = watch("question-1")?.value ?? "";
  const selectedQuestionTwo = watch("question-2")?.value ?? "";
  const selectedQuestionThree = watch("question-3")?.value ?? "";

  const options = useMemo(() => {
    const selectedQuestions = [
      selectedQuestionOne,
      selectedQuestionTwo,
      selectedQuestionThree,
    ];
    const formattedOptions = data?.map((question) => ({
      value: question?._id,
      label: question?.question,
    }));

    return formattedOptions?.filter(
      (option) => !selectedQuestions.includes(option?.value)
    );
  }, [data, selectedQuestionOne, selectedQuestionTwo, selectedQuestionThree]);
  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      is_2FA_enabled
        ? authService.updateSecurityQuestions(data)
        : authService.createSecurityQuestions(data),
    onSuccess: () => {
      handleLogout();
    },
  });
  const onSubmit = (data) => {
    const payload = {
      security_questions: [
        {
          question: data["question-1"]?.value,
          answer: data["answer-1"],
        },
        {
          question: data["question-2"]?.value,
          answer: data["answer-2"],
        },
        {
          question: data["question-3"]?.value,
          answer: data["answer-3"],
        },
      ],
    };

    mutate(payload);
  };
  return (
    <div className="mt-10">
      <div className="border-b border-gray-200 my-7"></div>
      <SubHeading>
        {is_2FA_enabled ? "Update" : "Create"} security questions
      </SubHeading>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        {is_2FA_enabled ? "Update" : "Create"} your security questions
        associated with your account.
      </p>
      <div className="border-b border-gray-200 mt-7"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" grid grid-cols-12 gap-10 mt-6 pr-[100px] items-center">
          {Array(3)
            .fill(3)
            .map((_, index) => (
              <>
                <div className="col-span-12 w-[320px] md:w-full lg:w-full">
                  <Select
                    label={`Question ${index + 1}`}
                    name={`question-${index + 1}`}
                    control={control}
                    options={options ?? []}
                    error={
                      errors[`question-${index + 1}`]
                        ? "This field is required"
                        : ""
                    }
                  />
                </div>

                <div className="col-span-12 w-[320px] md:w-full lg:w-full">
                  <Input
                    label="Answer"
                    {...register(`answer-${index + 1}`, { required: true })}
                    error={
                      errors[`answer-${index + 1}`]
                        ? "This field is required"
                        : ""
                    }
                  />
                </div>
              </>
            ))}
          <div className=" col-span-12 flex lg:justify-end md:justify-end justify-start mb-4">
            <Button type="submit" disabled={isPending}>
              {is_2FA_enabled
                ? "Update security questions"
                : "Create security questions"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
