import React from "react";
import { data } from "./navData";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowDown2,
  ArrowUp2,
  DocumentUpload,
  MoneyAdd,
  Trade,
} from "iconsax-react";
import { useState } from "react";
import { useEffect } from "react";

export const NavItem = ({ onClick }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const dropdownLinks = [
    {
      to: "/transfer-request",
      label: "Transfer Requests",
      icon: Trade,
    },
    // {
    //   to: "/transfer-request/batch-transfer",
    //   label: "Batch Transfer",
    //   icon: DocumentUpload,
    // },
    {
      to: "/transfer-request/single-transfer",
      label: "Single Transfer",
      icon: MoneyAdd,
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.pathname.includes("transfer")) {
      setDropdownVisible(false);
      setActiveLink(location.pathname);
    }
  }, [location]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const isLinkActive = (targetUrl) => {
    return location.pathname === targetUrl;
  };
  return (
    <div className="">
      {data?.map((item) => (
        <div>
          <NavLink
            onClick={onClick}
            className={({ isActive }) =>
              isActive && !dropdownVisible
                ? `mb-6 capitalize flex items-center gap-4 text-[15px]  p-3 font-semibold  rounded-[6px] bg-lighter text-primary `
                : `mb-6 capitalize flex items-center gap-4 text-[15px] p-3  text-primary font-normal`
            }
            to={item?.to}
          >
            {item?.iconLinear}
            <span> {item?.title}</span>
          </NavLink>
        </div>
      ))}
    </div>
  );
};
