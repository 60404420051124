import http from "../plugins/http";
import { notifyError, notifySuccess } from "../utils/toast";

class UserService {
  async createUser(payload) {
    try {
      const response = await http.post(`/users/create`, payload);
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async resendEmailVerification(payload) {
    try {
      const response = await http.post(
        `/users/resend-verification-email`,
        {...payload}
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async getUserProfile() {
    try {
      const response = await http.get(`/users/profile`);

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getUsers(params) {
    try {
      const response = await http.get(`/users`, { params });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
export const userService = new UserService();
