import { notification } from "./notification";
import { notifySuccess } from "./toast";

export const handleCopyClick = (text = "") => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);

  textArea.select();

  document.execCommand("copy");


  document.body.removeChild(textArea);

  notifySuccess("copied to clipboard: " + text);
};
