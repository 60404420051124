import React from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import { useEffect } from "react";
export const useTickets = () => {
  const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const [ticketResponse, setTicketResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    const collectionRef = firestore
      .collection("tickets")
      .orderBy("createdAt", "desc");
    const snapshot = await collectionRef.get();
    const newData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(newData);
    setIsLoading(false);
  };
  const collectionRef = firestore.collection("ticket-response");
  const fetchResponse = async () => {
    const snapshot = await collectionRef.get();
    const newData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTicketResponse(newData);
  };
  useEffect(() => {
    fetchResponse();
  }, [collectionRef]);
  useEffect(() => {
    fetchData();
  }, [location]);
  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await firestore.collection("tickets").doc(id).get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        setDetails(userData);
      }
    };

    fetchUserData();
  }, [id]);
  return {
    data,
    isLoading,
    ticketResponse,
    details,
  };
};
