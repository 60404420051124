import { Key, MoneyAdd, User} from "iconsax-react";

export const data = [
  {
    title: "Security",
    to: "/settings/security",
    icon: Key,
  },
  {
    title: "Mandate Rule",
    to: "/settings/mandate-rule",
    icon: MoneyAdd,
  },
  {
    title: "Roles & Permissions",
    to: "/settings/roles",
    icon: User,
  },
  // {
  //   title: "Set transaction pin",
  //   to: "/settings/transaction-pin",
  //   icon: Key,
  // },
];
