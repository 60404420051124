import { useQuery } from "@tanstack/react-query";
import { organizationService } from "../../../services/organization.service";

export const useRep = () => {
  const { data:rep } = useQuery({
    queryKey: ["get-organization"],
    queryFn: () => organizationService.getOrganization(),
  });
  return { rep };
};
