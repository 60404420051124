import {
  Calendar,
  Message,
  Send,
  Send2,
  UserOctagon,
  Wallet,
} from "iconsax-react";
import { formatDate, formatNaira, notification } from "../../../utils";
import { Badge, Button, TextArea } from "../../../components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { firestore, timeStamp } from "../../../firebase";
import man from "../../../assets/img/smile.avif";
export const TicketDetailsList = ({ details }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    const payload = {
      user: details?.user,
      message: data.message,
      createdAt: timeStamp(),
    };
    try {
      setIsLoading(true);
      const db = firestore;
      await db.collection("ticket-response").add(payload);
      reset();
      notification("Response Logged");
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className="border rounded-[10px] border-lighter">
        <dl className="grid grid-cols-12 gap-4  p-6  ">
          <dd className="mt-3 col-span-12 text-lg font-bold text-gray-900 flex items-center gap-5">
            <p>Topic:</p>

            <p className="font-normal text-[14px] capitalize">
              {" "}
              {details?.topic}
            </p>
          </dd>
          <div className="col-span-6">
            <dt className="text-sm  text-gray-700 text-[17px] font-medium flex items-center gap-3">
              <img
                src={man}
                className="h-[70px] w-[70px] rounded-full object-cover"
              />
              <span>{details?.user}</span>
            </dt>
          </div>
          <div className="col-span-6 text-right ">
            <dd className="mt-5 text-sm text-[#9FA2AB] flex items-center gap-2 ">
              <Calendar className="w-5 h-5 text-primary" />
              {formatDate(details?.createdAt?.seconds * 1000)}
            </dd>
          </div>
        </dl>

        {/* ticket message */}
        <dl className="grid grid-cols-12 gap-x-4 gap-y-8   p-6  border-b border-gray-300  relative">
          <div className="col-span-6">
            <dt className="text-sm font-medium text-[#9FA2AB]">
              Ticket Subject
            </dt>
            <dd className="mt-2 text-md text-[#3D4355]">{details?.message}</dd>
          </div>
          <div className="col-span-6">
            <dt className="text-sm font-medium text-[#9FA2AB]">Priority</dt>
            <div className="bg-green-600 w-[40px] h-[40px] rounded-full text-white flex items-center justify-center mt-2">
              <dd className=" text-md">P1</dd>
            </div>
          </div>
        </dl>

        <dl className="grid grid-cols-12 gap-x-4  p-6  gap-y-8 ">
          <form onSubmit={handleSubmit(onSubmit)} className="col-span-12">
            <div className="relative">
              <TextArea
                id="message"
                {...register("message", { required: true })}
                error={errors.message && "message is required"}
                label="Send Response"
                placeholder="Write something......."
              />
              <div className="absolute top-10 right-3 text-primary">
                <Send2 className="w-5" />
              </div>
            </div>
            <div className="flex justify-end  mt-9">
              <Button type="submit">{isLoading ? "Submitting" : "Send"}</Button>
            </div>
          </form>
        </dl>
      </div>
    </div>
  );
};
