import React from "react";
import {
  Button,
  Header,
  Heading,
  Pagination,
  SubHeading,
} from "../../components";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import { useState } from "react";
import { CreateTransfer } from "./CreateTransfer/CreateTransfer";
import ContentLoader from "react-content-loader";
import {useTableSerialNumber } from "../../hooks";
import { RenderData } from "./components/RenderData";
import { limit } from "../../constants/limit";
import { transactionService } from "../../services/transaction.service";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
export const Transfer = ({ children }) => {
  const navigate = useNavigate();
  const [value, setValue]=useState(undefined)
  const [page, setPage] = useState(1);
  const params = {
    limit: limit,
    page: page,
    search:value
  };
  const { data, isFetching,refetch } = useQuery({
    queryKey: ["all-transfers", params],
    queryFn: () => transactionService.getAllTransfers(params),
  });

  const initialSerialNumber = useTableSerialNumber(page);

  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start gap-3 ml-2 lg:ml-0">
          <Heading>Transfer Requests</Heading>
          <SearchFilter placeholder={"Search by amount, beneficiary account number or reference ID"} value={value} setValue={setValue} onSearch={refetch}/>
        </div>
      </Header>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-3 mt-16 lg:mt-0">
        <div>
          <SubHeading>All Transfers</SubHeading>
          <p className="my-3">All transfers made in your account. </p>
        </div>
        <Button
          variant="outline"
          onClick={() => {
            navigate("/transfer-request/single-transfer");
          }}
        >
          Create transfer
        </Button>
      </div>

      {isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            data={data?.items}
            initialSerialNumber={initialSerialNumber}
          />
          {data?.length !== 0 && (
            <div className="flex flex-col lg:flex-row items-center  justify-center mb-3 gap-y-6">
              <Pagination
                itemsPerPage={params.limit}
                totalItems={data?.meta?.total}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          )}
        </div>
      )}

      <CreateTransfer />
      <div>{children}</div>
    </div>
  );
};
