import { useForm } from "react-hook-form";
import { Button, Input } from "../../../components";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Shield } from "iconsax-react";
import { useLogout } from "../../../hooks/useLogout";

export const TwoFA = ({ payloadData, handlePrev }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [question, setQuestion] = useState();
  const [questionId, setQuestionId] = useState();
  const [authToken, setAuthToken] = useState();
  const { handleLogout } = useLogout();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.login(data),
    onSuccess: (data) => {
      if (data?.user?.login_id.startsWith("R")) {
        handleLogout();
        handlePrev();
      } else {
        navigate("/dashboard");
      }
    },
    onError: (err) => {
      setValue("answer", "");
      setQuestion(err?.response?.data?.message?.data?.question?.question ?? "");
      setQuestionId(err?.response?.data?.message?.data?.question?._id ?? "");
      setAuthToken(err?.response?.data?.message?.data["2fa_token"] ?? "");
    },
  });

  const onSubmit = (data) => {
    const payload = {
      token: authToken || payloadData["2fa_token"],
      questionId: questionId || payloadData?.question?._id,
      answer: data?.answer,
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <Shield
          className="w-12 h-12 text-primary mx-auto my-3"
          variant="Bold"
        />
      </div>
      <div className="flex  gap-2">
        <span className="text-gray-800">Question:</span>
      </div>
      <p className="text-primary font-[500] mb-3 mt-2">
        {question || payloadData?.question?.question}
      </p>
      <div className="space-y-8">
        <div>
          <Input
            label="Answer"
            id="answer"
            borderNone={true}
            {...register(`answer`, { required: true })}
            error={errors.answer ? "This field is required" : ""}
          />
          <span
            onClick={handlePrev}
            className="text-primary font-[600] text-sm  block text-right cursor-pointer mt-2"
          >
            Go back to login
          </span>
        </div>

        <Button type="submit" disabled={isPending}>
          Submit
        </Button>
      </div>
    </form>
  );
};
