import { useForm } from "react-hook-form";
import { Button, Input, Select } from "../../../components";
import { userService } from "../../../services/user.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { roleService } from "../../../services/role.service";

export const AddUser = ({ showModal, refetch }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: () => roleService.getRoles(),
  });
  const { mutate, isPending } = useMutation({
    mutationKey: ["create-users"],
    mutationFn: (data) => userService.createUser(data),
    onSuccess: () => {
      showModal();
      refetch();
    },
  });
  const onSubmit = (data) => {
    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone,
      role_id: data?.role?.value,
      gender: data?.gender?.value,
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <Input
        id="first_name"
        label="First Name"
        {...register("first_name", { required: true })}
        error={errors.first_name && "First name is required"}
      />
      <Input
        id="last_name"
        label="Last Name"
        {...register("last_name", { required: true })}
        error={errors.last_name && "Last name is required"}
      />

      <Input
        label="Email Address"
        id="email"
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        error={errors.email && "Email address is required"}
      />
      <Input
        label="Phone number"
        id="phone"
        {...register("phone", { required: true })}
        error={errors.phone && "Phone number is required"}
      />

      <Select
        name="gender"
        control={control}
        options={[
          {
            label: "Male",
            value: "male",
          },
          {
            label: "Female",
            value: "female",
          },
        ]}
        label="Gender"
        error={errors.gender && "Gender is required"}
      />
      <Select
        name="role"
        control={control}
        options={roles?.map((role) => ({
          label: role?.name,
          value: role?._id,
        }))}
        label="Role"
        error={errors.role && "Role is required"}
      />
      <Button type="submit" disabled={isPending} isFullWidth>
        Create User
      </Button>
    </form>
  );
};
