import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  ExportButton,
  Header,
  ModalComp,
  SubHeading,
} from "../../components";
import { useCard } from "./component/MockData";
import { HeaderDetails } from "./component/HeaderDetails";
import { useNavigate } from "react-router-dom";
import { formatDate, formatNaira } from "../../utils";
import ContentLoader from "react-content-loader";
import { RenderData } from "../TransactionHistory/components/RenderData";
import { ArrowRight2 } from "iconsax-react";
import { useTransactions } from "../TransactionHistory/hooks/useTransacions";
import { useProfile } from "../Account/hooks/useProfile";
import { useQuery } from "@tanstack/react-query";
import { accountService } from "../../services/account.service";
import { SecurityForm } from "../Settings/Security/components/SecurityForm";
import { useRoles } from "../Settings/Roles/hook/useRoles";
import { useAccountDetails } from "../Account/hooks/useAccountDetails";

export const Dashboard = () => {
  const { data, isLoading, initialSerialNumber } = useTransactions(10);

  const navigate = useNavigate();
  const { data: profile} = useProfile();
  const {role}=useRoles(profile?.role_id)
  const {account}=useAccountDetails()
  const [open, setOpen] = useState(
    profile?.is_default_password === true ? true : false
  );
  const { data: balance, isLoading: isBalanceLoading } = useQuery({
    queryKey: ["balance"],
    queryFn: () => accountService.getAccountBalance(),
  });
  const { cardData, isLoading: amountLoading } = useCard(
    balance,
    isBalanceLoading
  );

  return (
    <div>
      <Header>
        <HeaderDetails account={account} role={role} bal={balance} data={profile} />
      </Header>
      <div className="mb-4">
        <SubHeading>Overview</SubHeading>
      </div>
      <div className="grid grid-cols-12 gap-7">
        {cardData?.map((item, i) => (
          <div key={i}  className="lg:col-span-4 col-span-12">
            <Card card={item} key={item?.title} isLoading={amountLoading} />
          </div>
        ))}
        <div className="col-span-12 mt-10">
          <div className="flex justify-between items-center mb-6 ">
            <SubHeading>Recent Transactions</SubHeading>
            <Button
              onClick={() => {
                navigate("/transaction-history");
              }}
            >
              <div className="flex items-center gap-2">
                <span> view all</span>
                <ArrowRight2 className="w-5" />
              </div>
            </Button>
          </div>
          {isLoading ? (
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          ) : (
            <RenderData
              data={data?.items}
              initialSerialNumber={initialSerialNumber}
              view
            />
          )}
        </div>
      </div>

      {isLoading
        ? null
        : profile?.is_default_password === true && (
            <ModalComp
              dismissOnclickOutside={false}
              showCloseIcon={false}
              open={open}
              setOpen={setOpen}
            >
              <SecurityForm showSecurity={false} />
            </ModalComp>
          )}
    </div>
  );
};
