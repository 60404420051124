import React, { createContext, useContext, useState, useEffect } from "react";

const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState("#E78020");
  const [secondaryColor, setSecondaryColor] = useState("#00984c");
  const [borderColor, setBorderColor] = useState("#e7802085");
  const [lightColor, setLightColor] = useState("#e780201e");
  const [logo, setLogo] = useState(null);

  const handleLogoChange = (e) => {
    const selectedLogo = e.target.files[0];

    if (selectedLogo) {
      // Read the selected logo as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(selectedLogo);
    }
  };

  const handleRemoveLogo = () => {
    setLogo(null);
  };

  const defaultColors = {
    primary: "#E78020",
    secondary: "#00984c",
    light: "#e7802085",
    lighter: "#e780201e",
  };
  useEffect(() => {
    // Update root colors when state changes
    document.documentElement.style.setProperty("--color-primary", primaryColor);
    document.documentElement.style.setProperty(
      "--color-secondary",
      secondaryColor
    );
    document.documentElement.style.setProperty("--color-border", borderColor);
    document.documentElement.style.setProperty("--color-light", lightColor);
  }, [primaryColor, secondaryColor, borderColor, lightColor]);
  const resetColors = () => {
    setPrimaryColor(defaultColors.primary);
    setSecondaryColor(defaultColors.secondary);
    setBorderColor(defaultColors.light);
    setLightColor(defaultColors.lighter);
    handleRemoveLogo();
  };
  return (
    <ColorContext.Provider
      value={{
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        borderColor,
        setBorderColor,
        lightColor,
        setLightColor,
        resetColors,
        handleLogoChange,
        handleRemoveLogo,
        logo,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export const useColors = () => {
  return useContext(ColorContext);
};
