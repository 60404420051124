import http from "../plugins/http";
import { showAlert } from "../utils/sweetalert";
import { notifyError, notifySuccess } from "../utils/toast";

class TransactionService {
  async getAllTransfers(params) {
    try {
      const response = await http.get(`/transaction/transfer-requests`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTransactionHistory(params) {
    try {
      const response = await http.get(`/transaction/history`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTransferById(id) {
    try {
      const response = await http.get(`/transaction/transfer-requests/${id}`);

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTransactionByAccountId(account_id) {
    try {
      const response = await http.get(
        `/get_transaction_by_account_id/${account_id}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountLookup(params) {
    try {
      const response = await http.get(`/transaction/account-lookup`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountLookupNoBankcode(params) {
    try {
      const response = await http.get(
        `/transaction/account-lookup-no-bankcode`,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async initiateTransaction(type, payload) {
    try {
      const response = await http.post(
        type === "inter"
          ? `/transaction/transfer-requests/interbank`
          : `/transaction/transfer-requests/intrabank`,
        payload
      );
      showAlert(
        "Successful",
        response?.message ?? "Transaction initiated successfully",
        "success"
      );

      return response.data;
    } catch (error) {
      showAlert(
        "Error",
        error?.response?.data?.message ?? "Error initiating transaction",
        "error"
      );

      throw new Error(error);
    }
  }
  async generateBankStatement(params) {
    try {
      const response = await http.get(`/accounts/account-statement`, {
        params,
      });
      showAlert(
        "Successful",
        response?.message ?? "statement generated successfully",
        "success"
      );

      return response.data;
    } catch (error) {
      showAlert(
        "Error",
        error?.response?.data?.message ?? "error generating statement",
        "error"
      );

      throw new Error(error);
    }
  }
  async handleDecision(payload, decision) {
    try {
      const response = await http.put(
        `/transaction/transfer-request-decisions/${decision}`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async getDecisions(params) {
    try {
      const response = await http.get(
        `/transaction/transfer-request-decisions`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const transactionService = new TransactionService();
