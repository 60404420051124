import React from "react";

export const Checkbox = ({ checked, id, title, name, onChange, value }) => {
  return (
    <div className="relative flex items-start cursor-pointer">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          id={id}
          defaultChecked={checked}
          name={name}
          onChange={onChange}
          value={value}
          className="h-4 w-4 rounded border-[#E78020] text-primary focus:ring-primary cursor-pointer"
        />
        <div className="ml-3 text-sm leading-6 cursor-pointer">
          <label htmlFor={id} className="text-primary">
            {title}
          </label>
        </div>
      </div>
    </div>
  );
};
export const Checkbox2 = ({ checked, id, title, name, onChange, value , defaultChecked}) => {
  return (
    <div className="relative flex items-start cursor-pointer">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          defaultChecked={defaultChecked}
          name={name}
          onChange={(e)=>{
            onChange?.(e.target.checked)
          }}
          value={value}
          className="h-4 w-4 rounded border-[#E78020] text-primary focus:ring-primary cursor-pointer"
        />
        <div className="ml-3 text-sm leading-6 cursor-pointer">
          <label htmlFor={id} className="text-primary">
            {title}
          </label>
        </div>
      </div>
    </div>
  );
};
