import React from "react";
import { Button, Input, SubHeading } from "../../../../components";
import { useForm } from "react-hook-form";
import { SecurityQuestion } from "./SecurityQuestion";
import { useProfile } from "../../../Account/hooks/useProfile";
import { authService } from "../../../../services/auth.service";
import { useMutation } from "@tanstack/react-query";
import { useLogout } from "../../../../hooks/useLogout";

export const SecurityForm = ({ showSecurity = true }) => {
  const { data } = useProfile();
  const user_id = data?.user_id;
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { handleLogout } = useLogout();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.changePassword(data),
    onSuccess: () => {
      handleLogout();
    },
  });
  const onSubmit = (data) => {
    const payload = {
      user_id: user_id,
      new_password: data?.password,
      old_password: data?.old_password,
      confirm_password: data?.confirm_password,
    };
    mutate(payload);
  };
  return (
    <div>
      <div className=" gap-8 ">
        <div className="">
          <SubHeading>
            {showSecurity
              ? "Change password & update security question"
              : "Please kindly update default password"}
          </SubHeading>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            {showSecurity
              ? " Update your password associated with your account."
              : "This provides more security to your account"}
          </p>
        </div>
        <div className="border-b border-gray-200 mt-7"></div>
        {showSecurity ? (
          <form onSubmit={handleSubmit(onSubmit)} className=" mt-4 pr-[100px]">
            <div className=" grid grid-cols-12 items-center mt-6 gap-10">
              <div className="col-span-12 w-[320px] md:w-full lg:w-full">
                <Input
                  label="Current Password"
                  placeholder="XXXXX"
                  type="password"
                  id="old_password"
                  {...register("old_password", {
                    required: "Current password is required",
                  })}
                  error={errors.old_password && errors.old_password.message}
                />
              </div>

              <div className="col-span-12 grid grid-cols-1 lg:grid-cols-2 gap-6 w-[320px] md:w-full lg:w-full">
                <Input
                  id="password"
                  {...register("password", {
                    required: "New password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  })}
                  error={errors.password && errors.password.message}
                  label="New Password"
                  placeholder="XXX"
                  type="password"
                />
                <Input
                  label="Confirm Password"
                  placeholder="XXX"
                  type="password"
                  id="confirm_password"
                  {...register("confirm_password", {
                    validate: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords should match!";
                    },
                  })}
                  error={
                    errors.confirm_password &&
                    (errors.confirm_password?.message ??
                      "Confirm Password is required")
                  }
                />
              </div>
            </div>

            <div className="pt-6 col-span-4 flex lg:justify-end md:justify-end justify-start ">
              <Button disabled={isPending} type="submit">
                Change Password
              </Button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="">
              <Input
                label="Current Password"
                placeholder="XXXXX"
                type="password"
                id="old_password"
                {...register("old_password", {
                  required: "Current password is required",
                })}
                error={errors.old_password && errors.old_password.message}
              />

              <Input
                id="password"
                {...register("password", {
                  required: "New password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                })}
                error={errors.password && errors.password.message}
                label="New Password"
                placeholder="XXX"
                type="password"
              />
              <Input
                label="Confirm Password"
                placeholder="XXX"
                type="password"
                id="confirm_password"
                {...register("confirm_password", {
                  validate: (value) => {
                    const { password } = getValues();
                    return password === value || "Passwords should match!";
                  },
                })}
                error={
                  errors.confirm_password &&
                  (errors.confirm_password?.message ??
                    "Confirm Password is required")
                }
              />
            </div>

            <div className="pt-6  flex lg:justify-end md:justify-end justify-start ">
              <Button disabled={isPending} type="submit">
                Change Password
              </Button>
            </div>
          </form>
        )}
      </div>
      {showSecurity && (
        <SecurityQuestion is_2FA_enabled={data?.is_2FA_enabled} />
      )}
    </div>
  );
};
