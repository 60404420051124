import { useForm } from "react-hook-form";
import { LoginForm } from "./LoginForm";
import { useState } from "react";
import { authService } from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useHandleNextStep } from "../../../utils/useHandleNextStep";
import { TwoFA } from "./TwoFA";
import { AuthWrapper, Button } from "../../../components";
import { useModal } from "../../../hooks";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { VerifyOtpForm } from "../ForgotPassword/VerifyOtp/VerifyOtpForm";
import { useLogout } from "../../../hooks/useLogout";
import { notifyError } from "../../../utils/toast";
export const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleLogout } = useLogout();
  const { handleNextStep, handlePreviousStep, currentStep } =
    useHandleNextStep(2);
  const { showModal, Modal } = useModal();
  const [securityData, setSecurityData] = useState();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.preLogin(data),
    onSuccess: (data) => {
      if (data["2fa_token"]) {
        setSecurityData(data);
        handleNextStep();
      } else if (data?.user?.login_id.startsWith("R")) {
        handleLogout();
        notifyError(
          "You do not have access to this portal, kindly use the retail portal"
        );
      } else {
        navigate("/dashboard");
      }
    },
    onError: (err) => {
      if (
        err?.response?.data?.message === "You have not yet verified your email."
      ) {
        showModal();
      }
    },
  });
  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <LoginForm
            handleSubmit={handleSubmit}
            errors={errors}
            mutate={mutate}
            register={register}
            isPending={isPending}
          />
        );
      case 2:
        return (
          <TwoFA handlePrev={handlePreviousStep} payloadData={securityData} />
        );

      default:
        return null;
    }
  };
  let title = "";
  let subtitle = "";
  switch (currentStep) {
    case 1:
      title = "LOGIN";
      subtitle = "Login to your account";
      break;
    case 2:
      title = "Two-Factor Aunthentication";
      subtitle = "Answer your security question to gain access to your account";
      break;
  }
  return (
    <AuthWrapper showInstruction title={title} subtitle={subtitle}>
      {renderFormStep()}
      {Modal({
        children: (
          <div className="space-y-5">
            <h1 className="text-[23px] text-center">
              Please verify your email address
            </h1>
            <VerifyOtpForm showModal={showModal} />
          </div>
        ),
      })}
    </AuthWrapper>
  );
};
