import http from "../plugins/http";
import { showAlert } from "../utils/sweetalert";
import { notifyError, notifySuccess } from "../utils/toast";

class AuthService {
  async preLogin(payload) {
    try {
      const response = await http.post(`/users/pre-login`, payload);
      if (
        response.data?.auth_token &&
        !response?.data?.user?.login_id.startsWith("R")
      ) {
        notifySuccess(response?.message ?? "Login successful");
        localStorage.setItem("token", response.data?.auth_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data?.auth_token}`;
      }
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }

  async login(payload) {
    try {
      const { data } = await http.post(`/users/login`, payload);
      if (data?.auth_token) {
        localStorage.setItem("token", data?.auth_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data?.auth_token}`;
      }
      if (!data?.user?.login_id.startsWith("R")) {
        notifySuccess(data?.message ?? "Login successful");
      } else {
        notifyError(
          "You do not have access to this portal, kindly use the retail portal"
        );
      }
      return data;
    } catch (error) {
      notifyError(error?.response.data?.message?.message ?? "");
      return Promise.reject(error);
    }
  }

  async forgotPassword(payload) {
    try {
      const response = await http.post(`/users/forgot-password`, payload);
      notifySuccess(response?.message ?? "OTP was sent to your email address");
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }

  async resetPassword(payload) {
    try {
      const response = await http.post("/users/reset-password", payload);
      notifySuccess(response?.message ?? "Password reset successful");
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }

  async changePassword(payload) {
    try {
      const response = await http.put("/users/change-password", payload);
      notifySuccess(response?.message ?? "Password changed successfully");
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }
  async createSecurityQuestions(payload) {
    try {
      const response = await http.post(
        "/users/create-security-questions",
        payload
      );
      notifySuccess(
        response?.message ?? "Security questions created successfully"
      );
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }
  async updateSecurityQuestions(payload) {
    try {
      const response = await http.put(
        "/users/update-security-questions",
        payload
      );
      notifySuccess(
        response?.message ?? "Security questions updated successfully"
      );
      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }
  async resendEmailVerification(payload) {
    try {
      const response = await http.post(
        "/users/resend-verification-email",
        payload
      );
      notifySuccess(response?.message ?? "");
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }
  async emailVerification(payload) {
    try {
      const response = await http.post("/users/verify-email", payload);
      notifySuccess(response?.message ?? "");
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }
  async setPin(payload) {
    try {
      const response = await http.post(
        "/users/create-transaction-pin",
        payload
      );
      showAlert("Successful", response?.message ?? "", "success");

      return response.data;
    } catch (error) {
      showAlert("Error", error?.response?.data?.message ?? "", "error");

      notifyError(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }
  async updatePin(payload) {
    try {
      const response = await http.patch(
        "/users/change-transaction-pin",
        payload
      );
      showAlert("Successful", response?.message ?? "", "success");
      return response.data;
    } catch (error) {
      showAlert("Error", error?.response?.data?.message ?? "", "error");
      return Promise.reject(error);
    }
  }
  async generateOtp(payload) {
    try {
      const response = await http.post("/otp/send", payload);
      notifySuccess(response?.message ?? "OTP sent successfully");
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message ?? "error");
      return Promise.reject(error);
    }
  }
}

export const authService = new AuthService();
