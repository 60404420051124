import Swal from "sweetalert2";

export const showAlert = async (title, text, icon) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCloseButton: true,
    confirmButtonColor: "#e78020",
    confirmButtonText: "OK",
  });
};
