import React from "react";
import { Button, Select, SubHeading, TextArea } from "../../../components";
import { useForm } from "react-hook-form";
import { firestore, timeStamp } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { notification } from "../../../utils";
import { Send2 } from "iconsax-react";
export const TicketForm = () => {
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const payload = {
      user: data.issue.value,
      topic: data.issue.label,
      message: data.message,
      createdAt: timeStamp(),
    };
    try {
      setIsLoading(true);
      const db = firestore;
      await db.collection("tickets").add(payload);
      notification("ticket created successfully");
      navigate("/ticket");
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting data:", error);
    } finally {
      setIsLoading(false);

      navigate("/ticket");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <SubHeading>Create a new request</SubHeading>
        </div>
        <div className="flex flex-col gap-9">
          <Select
            label={<span>Select ticket topic</span>}
            name="issue"
            control={control}
            options={[
              {
                value: "Eze Samuel",
                label: "Transfer Request Error ",
              },
              {
                value: "OluwaYemesi Fola",
                label: "Account Settings",
              },
              {
                value: "Kadijat Yusuf",
                label: "User Management",
              },
            ]}
            error={errors.issue && "Topic is required"}
          />
          <div className="relative">
            <TextArea
              id="message"
              {...register("message", { required: true })}
              error={errors.message && "message is required"}
              label="Message"
              placeholder="Write something......."
            />
            <div className="absolute top-10 right-3 text-primary ">
              <Send2 className="w-5" />
            </div>
          </div>

          <div className=" pb-3 flex justify-end">
            <Button type="submit">{isLoading ? "Submitting" : "Send"}</Button>
          </div>
        </div>
      </form>
    </div>
  );
};
