import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";

export const Tabs = ({ tabs }) => {
  let currentTab = useQueryParams().get("tab");
  currentTab = currentTab || tabs[0].id;

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <div>
      <div className="border rounded-[10px] border-[#e7802027] mb-6 w-[300px]">
        <div className="flex items-center gap-10 p-3">
          {tabs.map((tab) => (
            <Link key={tab.id} to={`?tab=${tab.id}`}>
              <div
                className={`font-medium text-[15px] text-gray-700 ${
                  activeTab === tab.id
                    ? "bg-[#E78020] text-white p-2 w-[150px]"
                    : "p-2"
                } text-center rounded-[10px]`}
              >
                {tab.label}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div>{tabs?.find((tab) => tab.id === activeTab).content}</div>
    </div>
  );
};
