import { useQuery } from "@tanstack/react-query";
import { accountService } from "../../../services/account.service";


export const useAccountDetails = () => {

  const {data:account,isLoading} = useQuery({
    queryKey: ["get-account-details"],
    queryFn: () => accountService.getAccountDetails(),
  });
  return {account,isLoading};
};
