import React, { useState } from "react";
import banner from "../../assets/img/banner.jpg";
import Logo from "../Logo/Logo";
import { CloseCircle, Woman } from "iconsax-react";
export const AuthWrapper = ({ children, title, showInstruction }) => {
  const [hide, setHide] = useState(false);
  return (
    <div className="h-[100vh] ">
      <div>
        {showInstruction && (
          <div>
            {" "}
            {hide === false && (
              <div
                className={`${
                  hide === true && "opacity-0 duration-100"
                } absolute  left-7 z-50 top-12 w-[280px] h-auto bg-white rounded-2xl p-5`}
              >
                <div className="text-sm">
                  <CloseCircle
                    onClick={() => {
                      setHide(true);
                    }}
                    variant="Bold"
                    className="w-6 h-6 text-primary block absolute right-4 top-3 cursor-pointer"
                  />
                  <p className=" mt-8">
                    You can login with the demo details provided below
                  </p>
                  <p className="mt-2 text-primary font-bold">
                    Login ID: C311244815
                  </p>
                  <p className="text-primary mt-1 font-bold">
                    Password: Demo@1234
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div className="  lg:w-[60%]  w-[100%]  shadow-inner col-span-6 bg-primary  relative overflow-hidden hidden md:block lg:block">
          <div className="svg h-[200px] w-[200px] absolute bottom-0"></div>
          <div className="svg h-[200px] w-[200px] absolute top-0 right-0 rotate-[180deg]"></div>
          <div className="w-full h-full bg-[#8080801e] absolute z-30 flex items-center justify-center ">
            <div
              className="h-[400px] w-[400px] rounded-full relative z-6"
              style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
        <div className="bg-white p-8  lg:w-[40%]  w-[100%]  shadow-inner col-span-6 flex justify-center  flex-col border-[#00800027] border ">
          <div className="px-6 py-24">
            <div className="w-fit p-3 flex justify-center items-center   rounded-[10px] h-[50px] bg-white mb-1 ">
              <Logo />
            </div>
            <h1 className="font-bold text-[27px] ">{title && title}</h1>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
