import { useEffect, useRef, useState } from "react";
import { Button, Container, Input, Label } from "../../../components";
import { Heading } from "../../../components";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../services/auth.service";
import { useForm } from "react-hook-form";
import { useProfile } from "../../Account/hooks/useProfile";
import { useNavigate } from "react-router-dom";

export const ChangeTransactionPin = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { data: profile, isFetching } = useProfile();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      profile?.has_transaction_pin
        ? authService.updatePin(data)
        : authService.setPin(data),
    onSuccess: () => {
      navigate("/dashboard");
    },
  });
  const {
    mutate: generateOtp,
    isPending: isgenerating,
    data,
  } = useMutation({
    mutationFn: (data) => authService.generateOtp(data),
  });

  const onSubmit = (data) => {
    mutate(
      profile?.has_transaction_pin
        ? {
            code: data?.code,
            new_transaction_pin: data?.transaction_pin,
          }
        : { transaction_pin: data?.transaction_pin }
    );
  };
  return (
    <div className="lg:w-[400px]">
      <Container>
        {isFetching ? (
          ""
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6 "
          >
            <Heading>
              {profile?.has_transaction_pin === true
                ? "Update Transaction Pin"
                : "Create Transaction Pin"}
            </Heading>

            <div className=" space-y-6">
              {profile?.has_transaction_pin === true && (
                <div>
                  <Input
                    label="Enter OTP"
                    id="code"
                    {...register("code", { required: true })}
                    error={errors.code && "Enter OTP"}
                  />
                  <button
                    type="button"
                    disabled={isgenerating}
                    onClick={() => {
                      generateOtp({
                        type: "change_transaction_pin",
                      });
                    }}
                    className={`text-primary text-sm mt-1 block w-full text-right font-semibold text-right ${
                      isgenerating ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  >
                    {isgenerating ? (
                      "Generating OTP......"
                    ) : (
                      <div>Generate OTP</div>
                    )}
                  </button>
                </div>
              )}

              <Input
                label="Enter 4 digit Pin"
                id="transaction_pin"
                type="number"
                {...register("transaction_pin", {
                  required: false,
                  maxLength: 4,
                })}
                error={
                  errors.transaction_pin &&
                  "Transaction pin is required and must be 4 digit"
                }
              />
            </div>
            <Button disabled={isPending} type="submit">
              Continue
            </Button>
          </form>
        )}
      </Container>
    </div>
  );
};
