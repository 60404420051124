import React from "react";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { Button } from "../Button/Button";
import { useProfile } from "../../pages/Account/hooks/useProfile";

export const LogoutPrompt = ({ cancel, logout }) => {
  const { data } = useProfile();
  return (
    <div className="min-h-[170px]">
      <div className=" flex   flex-col mt-5">
        <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-red-100 mb-7">
          <ArrowLeftOnRectangleIcon className="w-5 h-5 text-red-500" />
        </div>
        <p className="font-semibold mt-9">
          {" "}
          {data?.first_name ?? "U"} {data?.last_name ?? "U"}{" "}
        </p>
        <p className="mt-4 mb-12">Are you sure you want to logout?</p>
      </div>
      <div className="flex items-center justify-end gap-5 mt-5">
        <div className="w-fit">
          <Button variant="outline" onClick={cancel} isFullWidth>
            Exit
          </Button>
        </div>
        <div className="w-fit">
          <Button variant="danger" onClick={logout} isFullWidth>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
