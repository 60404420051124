
import http from "../plugins/http";

class OrganizationService {
  async  getOrganization() {
    try {
     const response = await http.get(`/organizations/get-my-organization`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

}
export const organizationService= new OrganizationService();
