import { useNavigate } from "react-router-dom";
import { EmptyState } from "../../../components";
import { TransferTable } from "./TransferTable";

export const RenderData = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No transfer found"
        description="You have not made any transfer yet."
        showAction
        action={{
          label: "Create transfer",
          onClick: () => navigate("/transfer-request/single-transfer"),
        }}
      />
    );
  } else {
    return (
      <TransferTable data={data} initialSerialNumber={initialSerialNumber} />
    );
  }
};
