import React from "react";
import { Container, Header, Heading, SubHeading } from "../../../components";
import { Add } from "iconsax-react";
import { Link } from "react-router-dom";
import { RenderData } from "./component/RenderData";
import ContentLoader from "react-content-loader";
import { useQuery } from "@tanstack/react-query";
import { mandateRuleService } from "../../../services/mandate.service";
export const MandateRule = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["get-mandates"],
    queryFn: () => mandateRuleService.getAllMandateRule(),
  });

  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
          <Heading>Account Settings/mandate</Heading>
        </div>
      </Header>
      <Container>
        <div className="flex flex-col lg:flex-row lg:items-center  justify-between lg:mb-3 mb-4">
          <SubHeading>List of mandate rule</SubHeading>
          <Link
            to="/settings/mandate-rule/create"
            className="flex items-center gap-2 text-[14px] font-semibold text-primary border-[1px] border-[#e780208a] px-3 py-2 rounded-[5px]"
          >
            <Add className="w-5 h-5" />
            <span>create mandate</span>
          </Link>
        </div>
        {isFetching ? (
          <ContentLoader viewBox="0 0 380 70">
            <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
          </ContentLoader>
        ) : (
          <div>
            <RenderData data={data} />
          </div>
        )}
      </Container>
    </div>
  );
};
