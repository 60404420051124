import { useMutation, useQuery } from "@tanstack/react-query";
import { transactionService } from "../../../services/transaction.service";
import { useState } from "react";
import { useTableSerialNumber } from "../../../hooks";
import { limit as amount } from "../../../constants/limit";
import { notifySuccess } from "../../../utils/toast";

export const useTransactions = (limit = amount) => {
  const [page, setPage] = useState(1);
  const date = new Date().getFullYear();
  const initialSerialNumber = useTableSerialNumber(page);
  const params = { year: date, limit: limit, page: page };
  const { data, isLoading } = useQuery({
    queryKey: ["all-transaction", params],
    queryFn: () => transactionService.getTransactionHistory(params),
  });
  const { mutate: downloadAccountStatement, isPending: downloading } =
    useMutation({
      mutationKey: ["downloadStatement"],
      mutationFn: (payload) =>
        transactionService.generateBankStatement({
          from_date: payload.fromDate,
          to_date: payload.toDate,
          is_pdf: true,
        }),
      onSuccess: (data, payload) => {
        const base64 = data?.statement;
        const binary = atob(base64);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `account-statement-${new Date(
          payload.fromDate
        ).toDateString()}-${new Date(payload.toDate).toDateString()}-.pdf`;
        link.click();
        payload.showModal();
      },
    });

  return {
    data,
    isLoading,
    initialSerialNumber,
    page,
    setPage,
    params,
    accountStatement: {
      downloadAccountStatement,
      downloading,
    },
  };
};
