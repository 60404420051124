import http from "../plugins/http";

class SecurityService {
  async getSecurityQuestions() {
    try {
      const { data } = await http.get(`/meta-data/security-questions`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const securityService = new SecurityService();
