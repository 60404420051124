import React from "react";
import { Button, Input } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../../services/auth.service";

export const CreateNewpasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.resetPassword(data),
    onSuccess: () => {
      navigate("/");
    },
  });
  const onSubmit = (data) => {
    const payload = {
      code: data?.code,
      new_password: data?.password,
      confirm_password: data?.confirm_password,
    };
    mutate(payload);
  };
  return (
    <div className="pt-[40px]">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-12 ">
        <p className=" text-sm">
          Please secure your password, create a strong password to avoid scam or
          illegal activities
        </p>

        <Input
          label="Enter OTP"
          placeholder="Enter six digit OTP"
          id="code"
          {...register("code", { required: true })}
          error={errors.code && "OTP is required"}
          borderNone={true}
        />
        <Input
          label="New Password"
          placeholder="xxxxx"
          type="password"
          id="password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long",
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
              message:
                "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
            },
          })}
          borderNone={true}
          error={errors.password && errors.password.message}
        />
        <div>
          <Input
            label="Confirm Password"
            placeholder="xxxxx"
            type="password"
            id="confirm_password"
            {...register("confirm_password", {
              validate: (value) => {
                const { password } = getValues();
                return password === value || "Passwords should match!";
              },
            })}
            error={
              errors.confirm_password &&
              (errors.confirm_password?.message ??
                "Confirm Password is required")
            }
            borderNone={true}
          />
          <div className="text-sm flex justify-end font-bold mt-2">
            <Link to={"/forgot-password"} className=" text-primary">
              go back
            </Link>
          </div>
        </div>

        <div className="font-semibold">
          <Button disabled={isPending} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
