import React from "react";
import { Header, Heading, SubHeading } from "../../../components";
import {
  ArrangeVerticalSquare,
  ArrowLeft,
  Bubble,
  Message,
  Message2,
} from "iconsax-react";
import { Link } from "react-router-dom";
import { TicketDetailsList } from "./TicketDetailList";
import { TicketResponse } from "./TicketResponse";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { firestore } from "../../../firebase";
import { useTickets } from "../hooks/useTickets";
import { RenderDetails } from "../components/RenderDetails";
import { Spinner } from "../../../components/Spinner";
import { formatDate } from "../../../utils";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";
export const TicketDetails = () => {
  const { id } = useParams();
  const { details, ticketResponse, isLoading } = useTickets();
  return (
    <div>
      <Header>
        <Heading>Ticket Details</Heading>
        <p className="mt-2">
          {isLoading
            ? "......"
            : details?.user && (
                <p className="flex items-center gap-2">
                  <Bubble className="w-5 h-5 text-primary" />
                  {details?.user}
                </p>
              )}
        </p>
      </Header>
      {isLoading ? (
        <p className="text-[15px] font-semibold  flex flex-col h-[50vh] items-center justify-center">
          <Spinner></Spinner>
          <SubHeading>Fetching ticket details</SubHeading>
        </p>
      ) : (
        <div>
          {Object.keys(details)?.length !== 0 && (
            <div className="col-span-12 flex justify-end items-center">
              <HandleGoBack />
            </div>
          )}
          <RenderDetails ticketResponse={ticketResponse} details={details} />
        </div>
      )}
    </div>
  );
};
