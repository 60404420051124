import { EmptyState } from "../../../../components";
import { MandateTable } from "./MandateTable";
import { useNavigate } from "react-router-dom";
export const RenderData = ({ data }) => {
  const navigate = useNavigate();
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No mandate rule created"
        description="You have not created any mandate yet."
        showAction
        action={{
          label: "Create a new mandate",
          onClick: () => navigate("/settings/mandate-rule/create"),
        }}
      />
    );
  } else {
    return <MandateTable data={data} />;
  }
};
