import { Routes, Route, Outlet } from "react-router-dom";
import { Wrapper } from "../../components";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import TransactionHistory from "../../pages/TransactionHistory/TransactionHistory";
import { Transfer } from "../../pages/Transfers/Transfer";
import TransferDetails from "../../pages/Transfers/TransferDetails/TransferDetails";
import { Ticket } from "../../pages/Tickets/Ticket";
import { TicketDetails } from "../../pages/Tickets/TicketDetails/TicketDetails";
import { Audit } from "../../pages/Audit/Audit";
import { authRoute } from "./AuthRoute";
import { MandateRule } from "../../pages/Settings/MandateRule/MandateRule";
import { Settings } from "../../pages/Settings/Settings";
import { Security } from "../../pages/Settings/Security/Security";
import { SecurityForm } from "../../pages/Settings/Security/components/SecurityForm";
import { Report } from "../../pages/Reports/Report";
import { SingleTransfer } from "../../pages/Transfers/CreateTransfer/SingleTransfer";
import { BatchTransfer } from "../../pages/Transfers/CreateTransfer/BatchTransfer";
import { CreateTicket } from "../../pages/Tickets/CreateTicket/CreateTicket";
import { CreateMandate } from "../../pages/Settings/MandateRule/MandateForm/CreateMandate";
import { Profile } from "../../pages/Settings/Profile/Profile";
import { AccountInfo } from "../../pages/Account/Account";
import { Themes } from "../../pages/Settings/Themes/Themes";

import { PrivateOutlet } from "./PrivateOutLet";
import { Users } from "../../pages/User/User";
import { Roles } from "../../pages/Settings/Roles/Role";
import { RoleId } from "../../pages/Settings/Roles/RoleId";
import { ChangeTransactionPin } from "../../pages/Settings/Pin/Pin";

export const Router = () => {
  return (
    <div>
      <Routes>
        {authRoute.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route element={<PrivateOutlet />}>
          <Route
            element={
              <Wrapper>
                <Outlet />
              </Wrapper>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              element={
                <Settings>
                  <Outlet />
                </Settings>
              }
            >
              <Route path={"/settings"}>
                <Route index element={<Security />} />
                <Route path="profile" element={<Profile />} />
                <Route
                  path="transaction-pin"
                  element={<ChangeTransactionPin />}
                />
                <Route path="theme" element={<Themes />} />
                <Route path="security">
                  <Route index element={<Security />} />
                </Route>
                <Route path="roles">
                  <Route index element={<Roles />} />

                  <Route path=":id" element={<RoleId />} />
                </Route>
                <Route path="mandate-rule">
                  <Route index element={<MandateRule />} />

                  <Route path="create" element={<CreateMandate />} />
                  <Route path="edit/:mandate_id" element={<CreateMandate />} />
                </Route>
              </Route>
            </Route>

            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            />
            <Route path="/report" element={<Report />} />
            <Route path="/audit-logs" element={<Audit />} />
            <Route path="/account" element={<AccountInfo />} />
            <Route path="/transfer-request">
              <Route index element={<Transfer />} />
              <Route
                path="/transfer-request/details/:id"
                element={<TransferDetails />}
              />
              <Route
                path="/transfer-request/batch-transfer"
                element={<BatchTransfer />}
              />
              <Route
                path="/transfer-request/single-transfer"
                element={<SingleTransfer />}
              />
            </Route>

            <Route path="/ticket">
              <Route index element={<Ticket />} />
              <Route path="/ticket/details/:id" element={<TicketDetails />} />
              <Route path="/ticket/create" element={<CreateTicket />} />
            </Route>
            <Route path="/users" element={<Users />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};
