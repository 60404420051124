import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Header, Heading } from "../../components";
import { ArrowLeft } from "iconsax-react";
import { data } from "./Security/components/SettingsData";
import { useMemo } from "react";
import { useState } from "react";

export const Settings = ({ children }) => {
  const location = useLocation();
  const [path, setPath] = useState("");

  return (
    <div>
      <Header>
        <Heading>Account Settings</Heading>
      </Header>
      <div className="grid lg:grid-cols-12 grid-rows-12 gap-6 ml-[-10px] md:ml-0 lg:ml-0">
        <div className="col-span-3 flex lg:flex-col flex-row gap-[5px] lg:gap-[30px] w-fit ml-[-5px] md:ml-0 lg:ml-0 rounded-md">
          {data.map((item) => (
            <Link
              to={item.to}
              className={`${
                location.pathname === item.to
                  ? "border-b-[2px] border-primary bg-primary text-white"
                  : (item.to === "/settings/mandate-rule" &&
                      location.pathname === "/settings/mandate-rule/create") ||
                    (item.to === "/settings/mandate-rule" &&
                      location.pathname === "/settings/mandate-rule/edit")
                  ? "border-b-[2px] border-primary bg-primary text-white text-center"
                  : item.to === "/settings/profile" &&
                    location.pathname === "/settings"
                  ? "border-b-[2px] border-primary bg-primary text-white"
                  : ""
              } text-[14px] font-[500]   py-2 tracking-wide px-1.5 lg:px-6 rounded-md flex items-center gap-2`}
            >
              <item.icon className="w-4" />
              <span> {item.title}</span>
            </Link>
          ))}
        </div>

        <div className=" col-span-9 overflow-x-auto mt-5 lg:mt-0">{children}</div>
      </div>
    </div>
  );
};
