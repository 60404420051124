import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash.debounce";

const SearchFilter = ({ placeholder, value, setValue, onSearch, position }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
   setValue && setValue(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 1000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  const clearInput = () => {
    setValue("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setTimeout(() => {
      onSearch?.();
    }, 1);
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch?.();
        }}
        className={`relative w-full md:w-[420px] h-[40px] flex justify-center items-center ${position === "none" ? "" : "ml-[100px]"} rounded-[40px] bg-[#e780200e] border border-[#e7802048]`}
      >
        <input
          ref={inputRef}
          className="block pl-12 py-1 placeholder:text-[13px] border-0 outline-none w-full bg-transparent"
          placeholder={placeholder}
          onChange={debouncedResults}
        />
        {value?.length > 0 && (
          <button
            type="button"
            onClick={clearInput}
          >
            <XCircleIcon className="w-5 h-5 absolute top-2 right-3 text-gray-500" />
          </button>
        )}
        <button type="submit">
          <MagnifyingGlassIcon
            className="w-6 h-6 absolute top-2 left-4 text-primary"
          />
        </button>
      </form>
    </div>
  );
};

export default SearchFilter;
