import { Badge, SubHeading } from "../../../../components";
import { formatNaira } from "../../../../utils";
export const BatchTable = ({ data, status }) => {
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <div className="my-3">
        <SubHeading>Verify Uploads</SubHeading>
      </div>
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-1 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Amount
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Bank Name
            </th>
            <th className="px-2 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Bank Code
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Account Type
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Transfer Type
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Account Number
            </th>
            {status && (
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                <span>status</span>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-1 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {1 + i}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatNaira(item?.amount)}
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div>
                  <p className="mb-2">
                    {item?.beneficiaryBankName === ""
                      ? "LAPO"
                      : item?.beneficiaryBankName}
                  </p>
                  <p>{item?.payerAccountNumber}</p>
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                053
              </td>

              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.beneficiaryAccountType}
              </td>
              <td className="px-3 py-4 text-[12px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.type === "LAPO" ? "Inter-bank" : "Others"}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.beneficiaryAccountNumber}
              </td>
              {status && (
                <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-[#c0c0c052]">
                  <Badge status="completed">Resolved</Badge>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
