import React from "react";
import { Button, Input } from "../../../../components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../../services/auth.service";

export const VerifyOtpForm = ({ showModal }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.emailVerification(data),
    onSuccess: () => {
      showModal();
    },
  });
  const onSubmit = (data) => {
    mutate({ code: data?.OTP });
  };
  return (
    <div className="pt-[40px]">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 ">
        <p className=" text-sm">
          Kindly enter your six digit OTP sent sent to your account email
          address
        </p>
        <div>
          <Input
            label="Enter OTP"
            placeholder="xxxxxx"
            id="OTP"
            borderNone={true}
            autoComplete="false"
            {...register("OTP", { required: true })}
            error={errors.OTP && "OTP is required"}
          />
        </div>
        <div className="text-sm flex justify-end font-bold mt-2">
          <Link to={"/"} className=" text-primary">
            go back
          </Link>
        </div>
        <div className=" flex justify-between items-center font-semibold">
          <Button type="submit" disabled={isPending}>
            Verify
          </Button>
        </div>
      </form>
    </div>
  );
};
