import { ExportCircle } from "iconsax-react";
import React from "react";

import CircleLoader from "react-spinners/ClipLoader";

export const ExportButton = ({ children, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`flex items-center gap-3 text-primary text-[14px] border p-2 border-[#e7802071] rounded-md ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      {!disabled && <ExportCircle className="w-5 h-5" />}
      <span className="">
        {disabled ? <CircleLoader size={25} color={"#e78020"} /> : children}
      </span>
    </button>
  );
};

