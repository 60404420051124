import React from "react";
import { Switch } from "../DropDown/Switch";

export const Header = ({ children}) => {
  return (
    <div>
      <div className="fixed top-0 left-0 w-[100%] lg:pl-[280px] px-4 bg-[#fff]  shadow-sm border pb-3 lg:pt-5 pt-6 pr-10 z-[100] flex justify-between items-center">
        <div className="lg:mt-0 mt-20">{children}</div>
        <div className="lg:static absolute top-0 right-0 pr-4 lg:pr-0 ">
          <Switch/>
        </div>
      </div>
    </div>
  );
};
