import React from "react";
import { Badge, Greeting, Heading } from "../../../components";
import { Copy } from "iconsax-react";
import { handleCopyClick } from "../../../utils/handleCopy";

export const HeaderDetails = ({ data, bal, role, account }) => {
  return (
    <>
      <div>
        <div className="flex items-center  gap-5">
          <Heading>
            <div className="flex items-center gap-3">
              <div className="">
                <Greeting
                  name={`${data?.first_name ?? "...."} ${
                    data?.last_name ?? "...."
                  }`}
                />
              </div>
            </div>
          </Heading>
        </div>
        <div>
          <div className="text-gray-700 my-2 flex items-center flex-wrap">
            <p className="mr-1">Role:</p>
            <div className="mr-1">
              <Badge status="approved">{role?.name.replace("_", " ")}</Badge>
            </div>
          </div>
          {account && (
            <div className="flex items-center gap-2">
              <p>
                Account number:
                <span className="ml-1">{account?.account_number}</span>
              </p>
              <Copy
                variant="Bold"
                onClick={() => {
                  handleCopyClick(account?.account_number ?? "000");
                }}
                className="w-5 h-5 text-primary cursor-pointer"
              />
            </div>
          )}
        </div>
        <div className="flex items-center gap-2 text-primary mt-1 ">
          <p className="text-gray-700">
            Account type:{" "}
            <span className="text-primary text-sm">
              {bal?.account_type ?? "...."}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
