import { useEffect, useState } from "react";
const PER_PAGE = 5;
export const useTableSerialNumber = (page, perPage = PER_PAGE) => {
  const [initialSerialNumber, setInitialSerialNumber] = useState(1);

  useEffect(() => {
    setInitialSerialNumber((page - 1) * perPage + 1);
  }, [page, perPage]);

  return initialSerialNumber;
};
