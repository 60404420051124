import React from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ data, title, amount }) => {
  const options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: title,
      align: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return "₦" + val;
        },
      },
    },
    xaxis: {
      type: "category", // Use 'category' type for month labels
      categories: data,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return "₦" + val;
        },
      },
    },
  };

  const series = [
    {
      name: "XYZ MOTORS",
      data: amount,
      color: "var(--color-primary)",
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={550}
      />
    </div>
  );
};

export default Chart;
