import http from "../plugins/http";
import { notifyError, notifySuccess } from "../utils/toast";

class MandateRuleService {
  async createMandateRule(payload) {
    try {
      const response = await http.post(`/accounts/mandate-rules`, payload);

      notifySuccess(response?.message ?? "Mandate rule created successfully");

      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }
  async updateMandateRule(id, payload) {
    try {
      const response = await http.put(`/accounts/mandate-rules/${id}`, payload);

      notifySuccess(response?.message ?? "Mandate rule updated successfully");

      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }
  async getAllMandateRule() {
    try {
      const { data } = await http.get(`/accounts/mandate-rules`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getSingleMandateRule(id) {
    try {
      const { data } = await http.get(`/accounts/mandate-rules/${id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const mandateRuleService = new MandateRuleService();
