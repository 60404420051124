import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleLogout = (state = {}) => {
    localStorage.removeItem("token");
    queryClient.resetQueries()
    navigate("/", { replace: true, state });
  };

  return { handleLogout };
};
