export const useCard = (data, isLoading) => {
  const cardData = [
    {
      id: 1,
      title: "Avaliable Balance",
      amount: data?.available_balance,
    },
    {
      id: 2,
      title: "Ledger Balance",
      amount: data?.ledger_balance,
    },
    {
      id: 3,
      title: "Withdraw-able Balance",
      amount: data?.withdrawable_balance,
    },
  ];
  return {
    cardData,
    isLoading,
  };
};
