import React from "react";
import { useModal } from "../../../hooks/useModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { SingleTransferForm } from "./SingleTransferForm";
import { SubHeading } from "../../../components";
import { Tabs } from "../../../components/Tabs/Tabs";
import { BatchUpload } from "./BatchUpload/BatchUpload";
import { useConvertFileToJson } from "../../../hooks";
export const CreateTransfer = () => {
  const { Modal, setIsShowing } = useModal();
  const location = useLocation();
  const search = location.search;
  const { convertFile, file, clearFile, jsonArray } = useConvertFileToJson();
  useEffect(() => {
    if (search === "?tab=single-transfer") {
      setIsShowing(true);
    }
  }, [location]);
  return (
    <div>
      {Modal({
        children: (
          <div className="">
            <div className="my-5 px-3">
              <SubHeading>Make a Transfer</SubHeading>
            </div>
            <div>
              <Tabs
                tabs={[
                  {
                    id: "single-transfer",
                    label: "Single Transfer",
                    content: <SingleTransferForm setIshowing={setIsShowing} />,
                  },

                  {
                    id: "batch-transfer",
                    label: "Batch Transfer",
                    content: (
                      <BatchUpload
                        convertFile={convertFile}
                        file={file}
                        clearFile={clearFile}
                        jsonArray={jsonArray}
                        setIshowing={setIsShowing}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        ),
        showCloseIcon: true,
        size: file ? "lg" : "sm",
        nav: true,
        to: "/transfer-request",
        dismissOnclickOutside: false,
      })}
    </div>
  );
};
