import React from "react";
import { Eye, EyeSlash, Money3, Moneys } from "iconsax-react";
import { formatNaira } from "../../utils";
import { Wallet } from "iconsax-react";
import { useState } from "react";

export const Card = ({ card, children, isLoading }) => {
  const [toggle, setToggle] = useState(true);
  const handleToggle = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  return (
    <div className="rounded-[20px] p-7 bg-[#fff]  shadow-sm  border-[1px]">
      {card && (
        <div className="grid grid-cols-12">
          <div className="col-span-10">
            <div className="flex items-center gap-3">
              <span className="w-[55px] h-[55px] flex items-center justify-center bg-lighter rounded-full">
                <Moneys variant="Bold" className="block w-6 h-6 text-primary" />
              </span>
              <div>
                <p className=" text-[15px]">{card?.title}</p>
                <div className="mt-2">
                  {isLoading || card?.amount === undefined ? (
                    <p className="text-[18px] font-semibold text-gray-600">
                      {formatNaira(0.0)}
                    </p>
                  ) : (
                    <h2 className="text-[18px] font-semibold text-gray-600">
                      {toggle === true
                        ? formatNaira(card?.amount.replace(/,/g, ""))
                        : "xxxxxxxx"}
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
