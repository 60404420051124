import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/Form/Input/Input";
import { Select } from "../../../components/Form/Select/Select";
import { Button } from "../../../components";
import { useState } from "react";
import { useEffect } from "react";
import { useInitiateRequest } from "./hooks/useInitiateRequest";
import { transactionService } from "../../../services/transaction.service";
import { Tooltip } from "flowbite-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { notifyError } from "../../../utils/toast";
import { useProfile } from "../../Account/hooks/useProfile";
import { Refresh } from "iconsax-react";
import { authService } from "../../../services/auth.service";
export const SingleTransferForm = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [nameEnquiryLookupState, setNameEnquiryLookupState] =
    useState("pending");
  const [isDoingNameEnquiry, setIsDoingNameEnquiry] = useState(false);
  const accountNumber = watch("beneficiary_account_number");
  const bankCode = watch("beneficiary_bank");
  const accountName = getValues("beneficiary_account_name");
  const transferType = watch("transfer_type")?.value;
  const { data: profile } = useProfile();
  const providerType = watch("provider_type")?.value || "corebanking";
  const { bankList, initiateRequest, providers } = useInitiateRequest(
    transferType,
    providerType
  );

  const callInterbankNameEnquiry = useMemo(() => {
    return (
      transferType === "inter" && accountNumber?.length >= 10 && !!bankCode
    );
  }, [transferType, accountNumber, bankCode]);

  const callIntraBankNameEnquiry = useMemo(() => {
    return transferType === "intra" && accountNumber?.length >= 10;
  }, [transferType, accountNumber]);

  const { data } = useQuery({
    queryKey: ["name-enquiry", accountNumber, bankCode],
    queryFn: () => {
      setIsDoingNameEnquiry(true);
      setNameEnquiryLookupState("in-progress");
      return transactionService.getAccountLookup({
        bank_code: bankCode?.value,
        account_number: accountNumber,
      });
    },
    enabled: !!callInterbankNameEnquiry,
  });
  const { data: bankOne } = useQuery({
    queryKey: ["bank-one-name-enquiry", accountNumber],
    queryFn: () => {
      setIsDoingNameEnquiry(true);
      setNameEnquiryLookupState("in-progress");
      return transactionService.getAccountLookupNoBankcode({
        account_number: accountNumber,
      });
    },
    enabled: !!callIntraBankNameEnquiry,
  });
  useEffect(() => {
    if (data) {
      if (data?.is_successful) {
        setIsDoingNameEnquiry(false);
        setNameEnquiryLookupState("successful");
        setValue("beneficiary_account_name", data?.name);
        setValue("session_id", data?.session_id);
      } else {
        setNameEnquiryLookupState("error");
        setValue("beneficiary_account_name", "");
        setValue("session_id", "");
      }
      if (!data?.is_successful) {
        setNameEnquiryLookupState("error");
        setValue("beneficiary_account_name", "");
        setValue("session_id", "");
      }
    }
  }, [data, accountNumber, bankCode]);

  useEffect(() => {
    if (bankOne) {
      if (bankOne?.response_description === "Successful") {
        setIsDoingNameEnquiry(false);
        setNameEnquiryLookupState("successful");
        setValue("beneficiary_account_name", bankOne?.name);
        setValue("session_id", "1234566");
      } else {
        setNameEnquiryLookupState("error");
        setValue("beneficiary_account_name", "");
        setValue("session_id", "");
      }
    }
  }, [bankOne, accountNumber]);
  const onSubmit = (data) => {
    if (
      !data.beneficiary_account_name ||
      data.beneficiary_account_name === ""
    ) {
      notifyError("Unable to resolve account number", "error");
      return;
    }
    if (transferType === "inter") {
      const payload = {
        narration: data?.narration && data?.narration,
        amount: Number(data?.amount),
        destination_bank_code: data?.beneficiary_bank?.value,
        destination_account_name: data?.beneficiary_account_name,
        destination_account_type: data?.account_type?.value,
        destination_bvn: data?.destination_bvn,
        destination_kyc: data?.destination_kyc,
        destination_phone_number: data?.destination_phone_number,
        destination_account_number: data?.beneficiary_account_number,
        organization_id: profile?.organization_id,
        code: data?.code,
      };
      initiateRequest.mutate(payload);
    } else {
      const payload = {
        narration: data?.narration,
        amount: Number(data?.amount),
        destination_account_name: data?.beneficiary_account_name,
        destination_account_type: data?.account_type?.value,
        destination_account_number: data?.beneficiary_account_number,
        organization_id: profile?.organization_id,
        code: data?.code,
      };
      initiateRequest.mutate(payload);
    }
  };
  const { mutate: generateOtp, isPending: isgenerating } = useMutation({
    mutationFn: (data) => authService.generateOtp(data),
  });
  const validateNumber = (value) => {
    const numberPattern = /^\d*\.?\d*$/;
    return numberPattern.test(value);
  };
  return (
    <>
      <Tooltip
        anchorSelect=".tooltip-trigger"
        className="z-[1000] !bg-gray-500 text-gray-800"
      />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mt-7">
            Bank information
          </h3>
          <Input
            label="Amount"
            id="amount"
            {...register("amount", {
              required: true,
              validate: {
                isNumber: (value) =>
                  validateNumber(value) || "Amount must be a number",
              },
            })}
            error={errors.amount && "Amount is required and must be a number"}
          />
          <Select
            label="Beneficiary Account Type"
            name="account_type"
            control={control}
            options={[
              {
                value: "savings",
                label: "Savings",
              },
              {
                value: "current",
                label: "Current",
              },
            ]}
            error={errors.account_type && "Account type is required"}
          />

          <Select
            label="Transfer Type"
            name="transfer_type"
            control={control}
            onChange={(e) => {
              if (e.value === "intra") {
                setValue("beneficiary_bank", {
                  label: "GMFB",
                  value: "51276",
                });
              } else {
                setValue("beneficiary_bank", {
                  label: "",
                  value: "",
                });
              }
            }}
            options={[
              {
                value: "intra",
                label: "Same bank",
              },
              {
                value: "inter",
                label: "Other bank",
              },
            ]}
            error={errors.transfer_type && "Transfer type is required"}
          />
          {transferType === "inter" && (
            <Select
              label="Beneficiary Bank Name"
              name="beneficiary_bank"
              control={control}
              options={
                bankList?.map((bank) => ({
                  value: bank.code,
                  label: bank.name,
                })) || []
              }
              error={errors.beneficiary_bank && "Bank name is required"}
            />
          )}
          <Input
            label="Beneficiary Account Number"
            id="beneficiary_account_number"
            {...register("beneficiary_account_number", {
              required: true,
              min: 10,
            })}
            error={
              errors.beneficiary_account_number && "Account number is required"
            }
          />

          {nameEnquiryLookupState === "successful" && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Account Name:</span>
              <span className="text-sm text-gray-900">{accountName ?? ""}</span>
            </div>
          )}

          {nameEnquiryLookupState === "error" && (
            <div className="border-l-4 border-red-400 bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Unable to resolve account name. Please check the account
                    number and try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
       
        {transferType === "inter" && (
          <Input
            label="Destination phone number"
            id="destination_phone_number"
            {...register("destination_phone_number", { required: false })}
            error={
              errors.destination_phone_number && "Phone number is required"
            }
          />
        )}
        <div className="">
          <Input
            label="Enter OTP"
            id="code"
            {...register("code", { required: false })}
            error={errors.narration && "OTP is required"}
          />
          <button
            type="button"
            disabled={isgenerating}
            onClick={() => {
              generateOtp({
                type: "transaction",
              });
            }}
            className={`w-full flex items-center justify-end gap-1 text-right mt-2 text-sm text-primary ${
              isgenerating ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {isgenerating ? (
              "Generating OTP......"
            ) : (
              <div className="flex items-center gap-1">
                {" "}
                Generate OTP <Refresh className="w-4 h-4" />
              </div>
            )}
          </button>
        </div>
        <div className="">
          <Input
            label="Narration"
            id="narration"
            {...register("narration", { required: false, maxLength: 30 })}
            error={errors.narration && "Narration is required"}
          />
        </div>
        <div className="pt-8">
          <Button
            type="submit"
            disabled={isDoingNameEnquiry || initiateRequest.isPending}
            isFullWidth
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
