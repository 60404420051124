import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDjMOwiCbp8aCt8bs3cPpKZgtSEtlTkcps",
  authDomain: "lapo-774b6.firebaseapp.com",
  projectId: "lapo-774b6",
  storageBucket: "lapo-774b6.appspot.com",
  messagingSenderId: "712168078584",
  appId: "1:712168078584:web:d27d8506ebc1a144f84f0d",
  measurementId: "G-NH6RHYK0BJ",
};

// Initialize Firebase
export const timeStamp = firebase.firestore.FieldValue.serverTimestamp;
export const app = firebase.initializeApp(firebaseConfig);
export const firestore = app.firestore();
