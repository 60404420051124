import React from "react";
import { Button, Input } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../services/auth.service";

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.forgotPassword(data),
    onSuccess: () => {
      navigate("/create-password");
    },
  });
  const onSubmit = (data) => {
    mutate({ loginId: data?.loginId });
  };
  return (
    <div className="pt-[40px]">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-12 ">
        <p className=" text-sm">
          Kindly enter your Internet Banking Email address to recover password
        </p>
        <div>
          <Input
            label="Login ID"
            placeholder="Enter login id"
            id="loginId"
            borderNone={true}
            setIcon={true}
            {...register("loginId", { required: true })}
            error={errors.loginId && "Login ID is required"}
          />
          <div className="text-sm flex justify-end font-bold mt-2">
            <Link to={"/"} className=" text-primary">
              go back
            </Link>
          </div>
        </div>
        <div className=" w-[80px] font-semibold">
          <Button type="submit" disabled={isPending} isFullWidth>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};
