import React from "react";
import { Controller } from "react-hook-form";
import { Label } from "../Label/Label";
import ReactSelect from "react-select";
import ErrorMessage from "../Error/ErrorMessage";
export const Select = ({
  control,
  options,
  isMulti,
  name,
  error,
  label,
  placeholder,
}) => {
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      backgroundColor: "transparent",

      // This line disable the blue borders
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };
  return (
    <div className="relative">
      <p>{label && <Label label={label} />}</p>
      <div className="mt-1">
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactSelect
              placeholder={placeholder}
              {...field}
              styles={style}
              options={options}
              isMulti={isMulti}
              className="text-sm bg-[transparent]   border border-light rounded-md  text-gray-600"
            />
          )}
        />
      </div>
      {error && <ErrorMessage msg={error} />}
    </div>
  );
};
