import React, { useEffect, useMemo } from "react";
import { Button, Container, FileUpload } from "../../../../components";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { notification } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { firestore, timeStamp } from "../../../../firebase";

import { BatchTable } from "./BatchTable";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { Spinner } from "../../../../components/Spinner";
export const BatchUpload = ({
  convertFile,
  file,
  clearFile,
  jsonArray,
  stages = "step 1/3-upload file",
  setStages,
}) => {
  const [resolve, setResolve] = useState(false);
  const [initiate, setInitiate] = useState(false);
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!file) {
      setStages("step 1/3-upload file");
      setResolve(false);
      setInitiate(false);
    }
    if (resolve === true) {
      setStages("step 2/3-resolving accounts");
    }
    if (initiate === true) {
      setStages("step 3/3-initiate transfer");
    }
  }, [resolve, initiate]);
  const onSubmit = async () => {
    try {
      const promises = jsonArray?.map(async (row) => {
        const userData = {
          amount: Number(row?.amount),
          approval: row?.approval,
          bank_Name: row?.bank_Name,
          beneficiaryAccountNumber: row?.beneficiaryAccountNumber,
          beneficiaryAccountType: row?.beneficiaryAccountType,
          beneficiaryBankCode: row?.beneficiaryBankCode,
          beneficiaryBankName: row?.beneficiaryBankName,
          createdAt: timeStamp(),
          narration: row?.narration,
          payerAccountNumber: `0${row?.payerAccountNumber}`,
          status: row?.status,
          type: row?.type,
          approval_status: "success",
        };

        setIsLoading(true);
        const db = firestore;
        await db.collection("entries").add(userData);
      });

      await Promise.all(promises);

      setIsLoading(false);
      notification("transfer successful");

      navigate("/transfer-request");
      console.log("All users created successfully. Notify here.");
    } catch (error) {
      console.error("Error creating users:", error);
      setIsLoading(false);
      notification("transfer failed", "error");
    }
  };
  useEffect(() => {
    if (!file) {
      setResolve(false);
      setInitiate(false);
    }
  }, [file]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-7">
        <FileUpload
          accept=".csv, .xlsx, .xls"
          id="file-upload"
          infoText="Upload a CSV or Excel file"
          file={file}
          btn
          onChange={(file) => {
            convertFile(file);
          }}
          removeFile={() => {
            clearFile();
          }}
        >
          {resolve === true && jsonArray !== [] ? (
            <div className="mt-10">
              <Spinner>
                <p>Resolving Accounts....</p>
              </Spinner>
            </div>
          ) : (
            ""
          )}
          {file && resolve === false ? (
            <div>
              <div className="flex justify-end mb-5">
                {initiate === false && file ? (
                  <Button
                    type="button"
                    onClick={() => {
                      setResolve(true);
                      setTimeout(() => {
                        setResolve(false);
                        setInitiate(true);
                      }, 3000);
                    }}
                  >
                    <CloudArrowUpIcon className="w-5 h-5 mr-3" /> Upload File
                  </Button>
                ) : (
                  <div className="mt-10 flex justify-end">
                    {initiate === true ? (
                      <Button type="submit" isFullWidth>
                        {isLoading
                          ? " Initiating Transfer"
                          : " Initiate Transfer"}
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
              <Container>
                <BatchTable data={jsonArray} status={initiate === true} />
              </Container>
            </div>
          ) : null}
        </FileUpload>
      </form>
    </div>
  );
};
