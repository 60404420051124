import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import * as bankCodes from "../../../../json/bankCodes.json";
import { transactionService } from "../../../../services/transaction.service";
import { providerService } from "../../../../services/provider.service";
import { useState } from "react";

export const useInitiateRequest = (transfer_type, type) => {
  const navigate = useNavigate();
  const [nameEnquiryLookupState, setNameEnquiryLookupState] =
    useState("pending");
  const [isDoingNameEnquiry, setIsDoingNameEnquiry] = useState(false);
  const initiateRequest = useMutation({
    mutationFn: (data) =>
      transactionService.initiateTransaction(transfer_type, data),
    onSuccess: () => {
      navigate("/transfer-request");
    },
  });
  const { data: bankList } = useQuery({
    queryKey: ["bank-lists"],
    queryFn: () => {
      return bankCodes.default;
    },
  });
  const { data: providers } = useQuery({
    queryKey: ["providers", type],
    queryFn: () => {
      return providerService.getProviders({ type });
    },
    enabled: !!type,
  });

  return { bankList, initiateRequest, providers };
};
