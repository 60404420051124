import { HomeIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { UserPlusIcon as UserPlusLightIcon } from "@heroicons/react/24/outline";
import {
  Element2,
  Chart,
  Money,
  Ticket,
  Setting2,
  Activity,
  Trade,
  User,
  ChartCircle,
  Home3,
  MoneyAdd,
  Setting5,
  Setting4,
  MoneySend,
} from "iconsax-react";
export const data = [
  {
    title: "dashboard",
    to: "/dashboard",
    iconLinear: <Home3 className="w-5 h-5" />,
    iconBold: <Element2 variant="Bold" className="w-5 h-5" />,
  },
  {
    title: "transaction History",
    to: "/transaction-history",
    iconLinear: <Money className="w-5 h-5" />,
    iconBold: <Money variant="Bold" className="w-5 h-5" />,
  },
  {
    title: "transfers",
    to: "/transfer-request",
    iconLinear: <MoneyAdd className="w-5 h-5" />,
    iconBold: <Trade variant="Bold" className="w-5 h-5" />,
  },

  {
    title: "Account",
    to: "/account",
    iconLinear: <User className="w-5 h-5" />,
    iconBold: <User variant="Bold" className="w-5 h-5" />,
  },
  // {
  //   title: "tickets",
  //   to: "/ticket",
  //   iconLinear: <Ticket className="w-5 h-5" />,
  //   iconBold: <Ticket variant="Bold" className="w-5 h-5" />,
  // },
  {
    title: "Users",
    to: "/users",
    iconLinear: <UserPlusLightIcon className="w-5 h-5" />,
    iconBold: <UserPlusIcon className="w-5 h-5" />,
  },

  // {
  //   title: "audit logs",
  //   to: "/audit-logs",
  //   iconLinear: <Activity className="w-5 h-5" />,
  //   iconBold: <Activity variant="Bold" className="w-5 h-5" />,
  // },

  {
    title: "settings",
    to: "/settings",
    iconLinear: <Setting4 className="w-5 h-5" />,
    iconBold: <Setting2 variant="Bold" className="w-5 h-5" />,
  },
];
