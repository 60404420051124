import { CreateNewpassword } from "../../pages/Auth/ForgotPassword/CreateNewPassword/CreateNewpassword";
import ForgotPassword from "../../pages/Auth/ForgotPassword/ForgotPassword";
import VerifyOtp from "../../pages/Auth/ForgotPassword/VerifyOtp/VerifyOtp";
import { Login } from "../../pages/Auth/Login/Login";

export const authRoute = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/verify-email",
    element: <VerifyOtp />,
  },
  {
    path: "/create-password",
    element: <CreateNewpassword />,
  },
];
